// @ts-nocheck
import axios from "axios";
// import Cookies from "js-cookie";

// Global request interceptor
axios.interceptors.request.use(
  (config) => {
    // Set base url for api base
    /*config.baseURL = process.env.BASE_URL
      ? process.env.BASE_URL
      : "https://api.aide.app/v1/";*/

    if (!config.baseURL) {
      config.baseURL = process.env.NEXT_PUBLIC_API_BASE;
    }

    // get login token
    if (window.localStorage.getItem("aide_token")) {
      // Set global authorization bearer token
      config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "aide_token"
      )}`;
    }

    return config;
  }
  // (error) => Promise.reject(error)
);

// Global response interceptor

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (401 === error.response.status) {
      // Logout the SPA since the auth token is not valid
      window.localStorage.removeItem("aide_token");
      return (window.location = "/login");
    } else {
      return Promise.reject(error);
    }
  }
);
