import React, { createContext, useReducer } from "react";

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_TICKET":
      return {
        ...state,
        ticket: action.ticket,
      };
    case "SET_RESPONSE":
      return {
        ...state,
        aideResponse: action.aideResponse,
      };
    case "SET_QUICKSEARCH":
      return {
        ...state,
        quickSearch: action.quickSearch,
      };
    case "SET_HAS_QUICKSEARCH":
      return {
        ...state,
        hasQuickSearch: action.hasQuickSearch,
      };
    case "SET_ERROR":
      return {
        ...state,
        ticketError: action.ticket,
        aideResponseError: action.aideResponse,
        quickSearchError: action.quickSearch,
      };
    default:
      return state;
  }
};

const initialState = {
  ticket: null,
  aideResponse: null,
  quickSearch: null,
  ticketError: null,
  hasQuickSearch: false,
  aideResponseError: null,
  quickSearchError: null,
};

const Conversation = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Conversation;
