// @ts-nocheck
import "../styles/global.css";

import "../services/axios";
import "inter-ui/inter.css";

import { useEffect, useState } from "react";

import { Hydrate, QueryClient, QueryClientProvider } from "react-query";

import { RecoilRoot } from "recoil";
import Router, { useRouter } from "next/router";
// import { AuthProvider } from "../contexts/auth";
import Conversation from "../contexts/conversation";

import { FrontContextProvider } from "../providers/frontContext";

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient());
  // const router = useRouter();

  /**
   * Show welcome screen if user moves away to some other option
   * this effect is frontapp specific because it doesn't update context of app
   */
  // useEffect(() => {
  //   if ("front" === process.env.NEXT_PUBLIC_PLATFORM) {
  //     Front.contextUpdates.subscribe((context) => {
  //       switch (context.type) {
  //         case "noConversation":
  //           removeLocalStorage();
  //           Router.replace("/");
  //           break;
  //         case "singleConversation":
  //           if (!window.location.pathname.includes("single-conversation")) {
  //             Router.replace("/single-conversation");
  //           }
  //           break;
  //         case "multiConversations":
  //           removeLocalStorage();
  //           Router.replace("/");
  //           break;
  //       }
  //     });
  //   }
  // }, []);

  /**
   * Clear the localStorage when user moves away from conversation screen
   */
  const removeLocalStorage = () => {
    window.localStorage.removeItem("aide_selected_conversation");
    window.localStorage.removeItem("aide_quick_search_selected_conversation");
  };

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <FrontContextProvider>
            <Conversation>
              <Component {...pageProps} />
            </Conversation>
          </FrontContextProvider>
        </Hydrate>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default MyApp;
